<template>
  <ContainerBasic
    v-if="blockData.tilesToShow !== 0"
    container-type="FULL_WIDTH"
  >
    <div
      :class="[
        blockData.bgStyle && !blockData.hasDefaultBg
          ? 'pb-md md:pb-xl pt-xl'
          : '',
      ]"
      :style="[
        blockData.bgStyle && !blockData.hasDefaultBg
          ? `${blockData.bgStyle}`
          : '',
        gradient,
      ]"
    >
      <div
        class="w-full relative !bg-cover"
        :class="
          blockData.hasDefaultBg
            ? 'pt-md pb-sm touch:md:pt-xl touch:md:pb-md lg:pt-xl lg:pb-md'
            : ''
        "
      >
        <div v-if="blockData.hasDefaultBg" class="absolute top-0 w-full h-full">
          <div class="w-full h-full background"></div>
        </div>
        <ContainerBasic container-type="PAGE" class="z-10">
          <HeadlineDynamic
            v-if="blockData.headline"
            :has-decoration="true"
            level="h2"
            :headline="blockData.headline"
            class="mb-lg px-sm md:px-0"
            :style="blockData.textStyle"
          ></HeadlineDynamic>

          <TileView
            :is-slider="blockData.tilesToShow !== 1"
            :tiles="
              blockData.tilesToShow === 1
                ? [blockData.tiles[0]]
                : blockData.tiles
            "
            :grid-type="blockData.gridType"
          />
        </ContainerBasic>
      </div>
    </div>
  </ContainerBasic>
</template>

<script setup lang="ts">
import ContainerBasic from '@/components/components/container-basic.vue';
import HeadlineDynamic from '@/components/components/core/headline-dynamic/vdv/headline-dynamic.vue';
import TileView from '@/components/components/views/tileView/tile-view.vue';
import type { AutoblockData } from '../blockType';
import { useAreaStore } from '~/stores/useArea';
import { getGradientColorsViaAndTo } from '@/components/block/autoblock/utils';

const areaStore = useAreaStore();

const props = defineProps({
  blockData: {
    type: Object as PropType<AutoblockData>,
    required: true,
  },
});

const gradient =
  props.blockData.gradientColorsViaAndTo ?? props.blockData.hasDefaultBg
    ? getGradientColorsViaAndTo(areaStore.getActiveArea)
    : null;
</script>
<style lang="pcss" scoped>
.background {
  @media screen(md) {
    background: url('/image/autoblock_base_Bg.svg');
    background-size: cover;
    opacity: 0.4;
  }
}
</style>
